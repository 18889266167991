
import { Component, Vue } from 'vue-property-decorator'
import { KeepAlive } from '@/utils/decorators'
import { DataList } from '@/types/assestsManage'
@Component({
  name: 'AssestsManageList'
})
@KeepAlive
export default class extends Vue {
  private searchInfo = {
    name: '',
    cardType: ''
  }

  cardTypeList = ['未绑定', '正常', '即将到期', '已到期']

  private tableData: DataList[] = []
  private loading = true
  private total = 0
  private page = 1
  private size = 10

  created () {
    this.getData()
  }

  // 设置流量卡状态颜色
  setCardTypeStyle (list: {row: any; column: any; rowIndex: number}) {
    const colors = ['', '#43C79B', '#F36707', '#F30713']
    if (list.column.property === 'cardType') {
      return { color: colors[+list.row.cardType] }
    }
  }

  getData () {
    this.$axios.get(this.$apis.assestsManage.selectAssetByPage, {
      page: this.page,
      size: this.size,
      ...this.searchInfo
    }).then((res) => {
      this.tableData = res.list || []
      this.total = res.total || 0
    }).finally(() => {
      this.loading = false
    })
  }

  onSearch () {
    this.page = 1
    this.getData()
  }

  // 新增
  onAdd () {
    this.$router.push({
      name: 'assestsManageAdd'
    })
  }

  // 详情
  onDetail (id: string) {
    this.$router.push({
      path: `/assestsManage/detail/${id}`
    })
  }

  // 删除
  onDelete (assetId: string) {
    this.$confirm('确认删除该资产吗？', '提示').then(() => {
      this.$axios.post(this.$apis.assestsManage.deleteAsset, {
        assetId: assetId
      }).then(() => {
        this.$message.success('操作成功')
        this.getData()
      })
    })
  }
}
